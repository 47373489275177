<template>
  <div>
    <IbTabs />
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row
              align-v="center"
              align-h="between"
            >
              <b-col cols="12">
                <h4>
                  {{ $t('clients') }}
                </h4>
              </b-col>
              <b-col
                cols="12"
                class="my-2"
              >
                <b-alert
                  show
                  variant="primary"
                  class="p-2"
                >
                  <ul class="mb-0 text-justify">
                    <li :class="$store.state.appConfig.layout.skin === 'dark' &&'text-white'">
                      {{ $t('ib_update_description') }}
                    </li>
                  </ul>
                </b-alert>
                <b-alert
                  show
                  variant="primary"
                  class="p-2"
                >
                  <h4>{{ $t('ib.client-list.title') }}</h4>
                  <ul
                    :class="$store.state.appConfig.layout.skin === 'dark' &&'text-white'"
                    class="mb-0 text-justify"
                  >
                    <li>{{ $t('ib.client-list.description1') }}</li>
                  </ul>
                  <ul
                    :class="$store.state.appConfig.layout.skin === 'dark' &&'text-white'"
                    class="mb-0 text-justify"
                  >
                    <li>{{ $t('ib.client-list.description2') }}</li>
                  </ul>
                </b-alert>
              </b-col>
              <b-col class="d-lg-flex align-items-center">
                <b-form
                  class="px-1 flex-shrink-0 d-flex align-items-center flex-wrap w-100 justify-content-center justify-content-md-start"
                  style="gap: 8px;"
                >
                  <b-form-group :label="$t('client_name')">
                    <b-form-input
                      id="search"
                      v-model="filter.first_name"
                      :placeholder="$t('client_name')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('client_last_name')">
                    <b-form-input
                      id="search"
                      v-model="filter.last_name"
                      :placeholder="$t('client_last_name')"
                    />
                  </b-form-group>

                  <b-form-group :label="$t('client_id')">
                    <b-form-input
                      v-model="filter.userID"
                      :placeholder="$t('client_id')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('account_id')">
                    <b-form-input
                      v-model="filter.account"
                      :placeholder="$t('account_id')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('client_email')">
                    <b-form-input
                      v-model="filter.email"
                      :placeholder="$t('client_email')"
                    />
                  </b-form-group>
                  <b-form-group :label="$t('date')">
                    <div
                      class="d-flex relative "
                      style="width: 275px;"
                    >
                      <flat-pickr
                        ref="register-date"
                        v-model="filter.date"
                        class="form-control"
                        name="to-expiration"
                        :placeholder="$t('date')"
                        autocomplete="nope"
                        :clearable="true"
                        :config="flatPickerOptions"
                      />
                      <b-button
                        style="height: 38px;"
                        class="absoloute"
                        @click="clearDate()"
                      >
                        <feather-icon
                          icon="XIcon"
                          class=""
                          size="15"
                        />
                      </b-button>
                    </div>
                  </b-form-group>
                  <div class="w-100" />
                  <b-button
                    class="mx-1 mt-1"
                    style="height: 40px;width: 100px;"
                    variant="success"
                    @click="search"
                  >
                    {{ $t('search') }}
                  </b-button>
                </b-form>
              </b-col>
            </b-row>
            <!--  -->
            <b-row class="mt-1">
              <b-col cols="12">
                <b-table
                  responsive="lg"
                  no-border-collapse
                  striped
                  hover
                  :fields="membersfields"
                  :items="membersTable"
                  @sort-changed="onSortChanged"
                >
                  <template
                    #cell(last_balance)="data"
                  >
                    <span dir="ltr">${{ data.item.last_balance.toFixed(2) }}</span>
                  </template>
                  <template
                    #cell(last_equity)="data"
                  >
                    <span dir="ltr">${{ data.item.last_equity.toFixed(2) }}</span>
                  </template>
                  <template
                    #cell(total_commission)="data"
                  >
                    <span dir="ltr">${{ data.item.total_commission.toFixed(2) }}</span>
                  </template>
                  <template
                    #cell(total_lot)="data"
                  >
                    <span dir="ltr">{{ data.item.total_lot.toFixed(2) }}</span>
                  </template>
                  <template
                    #cell(total_trade)="data"
                  ><span dir="ltr">{{ data.item.total_trade }} {{ $t('number') }}</span>
                  </template>
                  <template
                    #cell(total_deposit)="data"
                  >
                    <span dir="ltr">${{ data.item.total_deposit.toFixed(2) }}</span>
                  </template>
                  <template
                    #cell(total_withdrawal)="data"
                  >
                    <span dir="ltr">${{ data.item.total_withdrawal.toFixed(2) }}</span>
                  </template>
                  <template #cell(reffer_link)="data">
                    <feather-icon
                      v-if="data.item.reffer_link"
                      icon="CheckIcon"
                    />
                    <feather-icon
                      v-else
                      icon="XIcon"
                    />
                  </template>
                  <template #cell(sub_id)="data">
                    <feather-icon
                      v-if="data.item.sub_id"
                      icon="CheckIcon"
                    />
                    <feather-icon
                      v-else
                      icon="XIcon"
                    />
                  </template>
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      dropleft
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>

                      <b-dropdown-item
                        :to="{ name: 'ib-client-details', params: { userType: 'client', userID: data.item.ib_client_id } }"
                      >
                        <feather-icon
                          icon="UserIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('details') }}</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        :to="{ name: 'ib-clients-accounts', params: { userType: 'client', userID: data.item.ib_client_id } }"
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('accountsAndDeposit') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{ name: 'ib-client-trade-history', params: { userType: 'client', userID: data.item.ib_client_id, market: 'forex' } }"
                      >
                        <feather-icon
                          icon="CornerUpRightIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('tradeHistory') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        :to="{ name: 'ib-client-money-transfer-history', params: { userType: 'client', userID: data.item.ib_client_id } }"
                      >
                        <feather-icon
                          icon="CornerDownRightIcon"
                          class="mr-50"
                        />
                        <span>{{ $t('moneyTransferHistory') }}</span>
                      </b-dropdown-item>

                    </b-dropdown>
                  </template>
                </b-table>
                <b-col
                  v-if="membersTable.length === 0"
                  class="text-center"
                >
                  {{ $t('no_item_exsist') }}
                </b-col>
              </b-col>
            </b-row>
            <b-row align-h="center">
              <b-col cols="auto">
                <b-pagination
                  v-if="pagination.total > pagination.per_page"
                  v-model="pagination.page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  align="fill"
                  size="sm"
                  class="mt-2 mb-1"
                  @input="getData()"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BTable,
  BPagination,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import IbTabs from '../components/ibTabs.vue'
import PannelIbApis from '@/modules/IB/services/apis/index'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const IB = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,
    IbTabs,
    BAlert,
    flatPickr,
  },

  data() {
    return {
      flatPickerOptions: {
        plugins: [
          new MonthSelectPlugin({
            shorthand: true, // defaults to false
            dateFormat: 'Y-m', // defaults to "F Y"
            altFormat: 'F Y', // defaults to "F Y"
            theme: 'dark', // defaults to "light"
          }),
        ],
      },
      filter: {
        first_name: '',
        last_name: '',
        client_id: '',
        date: '',
        email: '',
        account: '',
      },
      pagination: {
        page: 1,
        per_page: 30,
        total: 0,
      },
      sort: {
        sort: '',
        order_by: 'desc',
      },
      show: true,
      membersTable: [],
    }
  },

  computed: {
    membersfields() {
      return [
        { key: 'ib_client_id', label: this.$t('client_id') },
        { key: 'client_name', label: this.$t('name') },
        { key: 'last_balance', label: this.$t('balance'), sortable: true },
        { key: 'last_equity', label: this.$t('equity'), sortable: true },
        { key: 'total_lot', label: this.$t('total_lot'), sortable: true },
        { key: 'total_commission', label: this.$t('totalCommission'), sortable: true },
        { key: 'total_trade', label: this.$t('total_trade'), sortable: true },
        { key: 'total_deposit', label: this.$t('total_deposit'), sortable: true },
        { key: 'total_withdrawal', label: this.$t('total_withdrawal'), sortable: true },
        // { key: 'commission', label: this.$t('commissions') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.show = true
      const data = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
      }
      IB.clients(data).then(res => {
        this.membersTable = res.data.results.data
        this.pagination.total = res.data.results.pagination.total
        // this.pagination.page = res.data.results.pagination.page
      }).finally(() => {
        this.show = false
      })
    },
    onSortChanged(sort) {
      console.log(sort)
      this.sort.sort = sort.sortBy
      this.sort.order_by = sort.sortDesc ? 'desc' : 'asc'
      this.getData()
    },
    search() {
      this.getData()
    },
    detailsPage(item) {
      this.$router.push(`${this.$route.path}/${item.id}/details`)
    },
    subIdPage(item) {
      this.$router.push(`${this.$route.path}/${item.id}/subId`)
    },
    clearDate() {
      const datePickerWrap = this.$refs['register-date']
      if (!datePickerWrap) {
        return
      }
      datePickerWrap.fp.clear()
    },
  },
}
</script>

<style scoped>
.ibpannel-checkbox-group .btn.active {
  filter: brightness(60%);
}

::v-deep .dropdown-menu.show {
  width: max-content;
}
</style>
